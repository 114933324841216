@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'EncodeSans';
    src: url('EncodeSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}